#navbar1 {
  background: #000;
  width: 100%;
  padding: 1.5rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 20px 50px rgb(2, 2, 42);
}

.sticky {
  position: fixed;
  z-index: 5;
}

.logo1 {
  margin-left: 3%;
  color: #0f00e6;
  width: auto !important;
  height: 4rem !important;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav-list {
  font-size: 1.5rem !important;
  margin: 0 35px;
  color: rgb(100, 212, 220);
  text-decoration: none;
  /* font-weight: bold; */
  position: relative;
  cursor: pointer; /* Make the nav-list items clickable */
}

.dropdown-content {
  display: none;
  font-size: 1.25rem !important;
  position: absolute;
  top: 100%;
  margin-left: -15px;
  background-color: #000000;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #000;
}

.nav-list.active .dropdown-content {
  display: block;
}

.wallet {
  background-color: #0026e6;
  color: #F5F5F5;
  border-radius: 20px;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.wallet:hover {
  background-color: #0A3362;
  color: #F5F5F5;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}
