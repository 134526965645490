.mobile__navbar {
  position: relative;
  opacity: 0.9;
  z-index: 1;
  background-color: #000;
  position: sticky;
  top: 0px;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.mobile__navbar, .mobile__inner__navbar {
  width: 100%;
}


.mobile__navbar, .mobile__inner__navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.mobile__inner__navbar {
  background-color: #000;
  border-radius: 15px;
  height: 10%;
}

.right_navbar, .left_navbar {
  width: 47%;
}

.tbh__logo {
  width: 40px;
  float: left;
}

.navbar__icon, .close__icon {
  padding: 4px;
  width: 43px;
  height: 70px;
  float: right;
  border-radius: 5px;
}

.close__icon {
  margin-right: 20px;
  margin-top: 20px;
}

.mobile__navbar__menu {
  position: absolute;
  top: 0px;
  width: 100%;
  background-color: #000;
  opacity: 0.8;
  height: -moz-fit-content;
  height: fit-content;
  opacity: 100%;
  align-items: center;
}

.inner__mobile__navbar__menu li {
  opacity: 100%;
  background-color: #000;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  color: rgb(253, 253, 253);
  padding-left: 6%;
  font-size: 20px;
  text-align: left;
}

.inner__mobile__navbar__menu li hr {
  width: 90%;
  border-bottom: 1px solid white;
  margin-top: 5px;
}

.loader-container1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}
