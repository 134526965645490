.container1{
    width: 90vw;
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-wrap:wrap;
    
  }
  
  .container1 .card{
    position: relative;
  }
  
  .container1 .card .face{
    width:320px;
    height: 250px;
    transition:.4s;
    
  }
  
  .container1 .card .face.face1{
    position: relative;
    border-radius: 20px;
    background: #06b6d4;
    display: flex;
    justify-content: center;
    align-content:center;
    align-items: center;
    z-index: 1;
    transform: translateY(100px);
  }
  
  .container1 .card:hover .face.face1 {
    transform: translateY(0);
    box-shadow:
      inset 0 0 60px #06b6d4,    /* cyan-500 */
      inset 20px 0 80px #0ff,     /* cyan lighter shade */
      inset -20px 0 80px #06b6d4, /* cyan-500 */
      inset 20px 0 300px #0ff,    /* cyan lighter shade */
      inset -20px 0 300px #06b6d4,/* cyan-500 */
      0 0 50px #fff,              /* white for glow effect */
      -10px 0 80px #06b6d4,       /* cyan-500 */
      10px 0 80px #0ff;           /* cyan lighter shade */
  }
  
  
  
  .container1 .card .face.face1 .content{
    opacity: 1;
    transition:  0.5s;
    text-align: center;
  }
  
  .container1 .card:hover .face.face1 .content{
    opacity: 1;
   
  }
  
  .container1 .card .face.face1 .content i{
    font-size: 3em;
    color: rgb(61, 31, 145);
    display: inline-block;
     
  }
  
  .container1 .card .face.face1 .content a{
     transition: .5s;
  }
  
  .container1 .card .face.face2{
     position: relative;
     border-radius: 20px;
     background: #0891b2;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(54, 64, 178, 0.8);
    transform: translateY(-150px);
  }
  
  .container1 .card:hover .face.face2{
      transform: translateY(0);
  }
  
  .container1 .card .face.face2 .content{
    font-size: 10pt;
    margin: 0 ;
    padding: 0;
    color:#ffffff;
  }
  
  .container1 .card .face.face2 .content a{
    text-decoration:none;
    color: black;
    box-sizing: border-box;
    outline : 1px dashed #333;
    padding: 10px;
    margin: 15px 0 0;
    display: inline-block;
  }
  
  .container1 .card .face.face2 .content a:hover{
    opacity: 1;
    background: #333 ;
    color: whitesmoke; 
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
  }